<template>
  <div>
    <ManageTeacherDetails />
  </div>
</template>
<script>
import ManageTeacherDetails from "@/components/ManageTeacherDetails/ManageTeacherDetails";
export default {
  components: {
    ManageTeacherDetails,
  },
  created() {},
};
</script>
